import React from 'react';
import { noop, omit } from 'lodash';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { createForm, formShape } from 'rc-form';
import moment from 'moment';

import { snippetConfigShape } from 'shapes/snippetConfig';
import { isTruthyOrZero } from 'utils';

import { DISPLAY_FREQUENCY, DEFAULT_NB_CONNECTIONS } from '../snippet-configuration-form.constants';

export default (WrappedComponent) => {
  const enhancer = compose(
    createForm(),

    withHandlers({
      /*
       * Reset fields to their default value when top-level switch is toggled to false.
       */
      onChangeShouldOpenSnippetOnOpeningWindow: ({ form }) => newValue => !newValue
        && form.setFieldsValue({ visitsBeforeOpeningWindow: DEFAULT_NB_CONNECTIONS }),
      onChangeShouldLimitPopupActivationForPeriod: ({ form }) => newValue => !newValue
        && form.setFieldsValue({ startDate: null, endDate: null }),
      onChangeShouldAskFeedbackIfUserAlreadyVoted: ({ form }) => newValue => !newValue
        && form.setFieldsValue({ displayingFrequencyIfUserAlreadyVoted: null }),
      /*
       * Submit handler.
       */
      onSubmit: ({ onSubmit, form }) => (event) => {
        event.preventDefault();
        event.stopPropagation();

        form.validateFields((err, values) => {
          if (!err) {
            // If the toggle was selected but no value was set in the subfield, reset toggles.
            if (!values.startDate && !values.endDate) {
              form.setFieldsValue({ shouldLimitPopupActivationForPeriod: false });
            }
            if (!values.displayingFrequencyIfUserAlreadyVoted) {
              form.setFieldsValue({ shouldAskFeedbackIfUserAlreadyVoted: false });
            }

            // Provide default values if toggle were not selected.
            if (!values.shouldOpenSnippetOnOpeningWindow) {
              values.visitsBeforeOpeningWindow = 0;
            }
            if (!values.shouldLimitPopupActivationForPeriod) {
              values.startDate = null;
              values.endDate = null;
            }
            if (values.startDate) {
              values.startDate = values.startDate.toISOString();
            }
            if (values.endDate) {
              values.endDate = values.endDate.toISOString();
            }
            if (!values.shouldAskFeedbackIfUserAlreadyVoted) {
              values.displayingFrequencyIfUserAlreadyVoted = DISPLAY_FREQUENCY.NEVER;
            }

            // Omit toggle values to API response.
            omit(values, ['shouldLimitPopupActivationForPeriod']);
            omit(values, ['shouldAskFeedbackIfUserAlreadyVoted']);

            // Aaaaand finally submit.
            onSubmit({
              ...values,
            });
          }
        });
      },
    }),
  );

  const SnippetConfigurationFormContainer = ({
    form,
    snippetConfig,
    onSubmit,
    onChangeShouldOpenSnippetOnOpeningWindow,
    onChangeShouldLimitPopupActivationForPeriod,
    onChangeShouldAskFeedbackIfUserAlreadyVoted,
    ...props
  }) => (
    <WrappedComponent
      {...props}
      onSubmit={onSubmit}
      form={form}
      // Should show button
      shouldShowButtonAddFeedbackDecorator={form.getFieldDecorator(
        'shouldShowButtonAddFeedback',
        {
          initialValue: !!snippetConfig?.shouldShowButtonAddFeedback,
          valuePropName: 'checked',
        },
      )}
      // Should open snippet when the user open his application
      shouldOpenSnippetOnOpeningWindowDecorator={form.getFieldDecorator(
        'shouldOpenSnippetOnOpeningWindow',
        {
          initialValue: snippetConfig?.shouldOpenSnippetOnOpeningWindow,
          valuePropName: 'checked',
          onChange: onChangeShouldOpenSnippetOnOpeningWindow,
        },
      )}
      // How many connections before opening the snippet at the application opening
      visitsBeforeOpeningWindowDecorator={form.getFieldDecorator(
        'visitsBeforeOpeningWindow',
        {
          initialValue: isTruthyOrZero(snippetConfig?.visitsBeforeOpeningWindow) ?
            snippetConfig?.visitsBeforeOpeningWindow : DEFAULT_NB_CONNECTIONS,
        },
      )}
      // Should limit the opening on a specific date period
      shouldLimitPopupActivationForPeriodDecorator={form.getFieldDecorator(
        'shouldLimitPopupActivationForPeriod',
        {
          initialValue: !!(snippetConfig?.startDate || snippetConfig?.endDate),
          valuePropName: 'checked',
          onChange: onChangeShouldLimitPopupActivationForPeriod,
        },
      )}
      startDateDecorator={form.getFieldDecorator(
        'startDate',
        {
          initialValue: snippetConfig?.startDate ? moment(snippetConfig.startDate) : null,
        },
      )}
      endDateDecorator={form.getFieldDecorator(
        'endDate',
        {
          initialValue: snippetConfig?.endDate ? moment(snippetConfig.endDate) : null,
        },
      )}
      // Should ask a user who has already voted
      shouldAskFeedbackIfUserAlreadyVotedDecorator={form.getFieldDecorator(
        'shouldAskFeedbackIfUserAlreadyVoted',
        {
          initialValue: !!snippetConfig?.displayingFrequencyIfUserAlreadyVoted,
          valuePropName: 'checked',
          onChange: onChangeShouldAskFeedbackIfUserAlreadyVoted,
        },
      )}
      // The time to wait before opening the snippet if the user has already voted
      displayingFrequencyIfUserAlreadyVotedDecorator={form.getFieldDecorator(
        'displayingFrequencyIfUserAlreadyVoted',
        {
          initialValue: snippetConfig?.displayingFrequencyIfUserAlreadyVoted
            || DISPLAY_FREQUENCY.NEVER,
        },
      )}
      shouldOpenSnippetOnOpeningWindow={form.getFieldValue('shouldOpenSnippetOnOpeningWindow')}
      shouldLimitPopupActivationForPeriod={form.getFieldValue('shouldLimitPopupActivationForPeriod')}
      shouldAskFeedbackIfUserAlreadyVoted={form.getFieldValue('shouldAskFeedbackIfUserAlreadyVoted')}
      setValueNbConnections={value => form.setFieldsValue({
        nbConnectionsBeforeOpening: value,
      })}
    />
  );

  SnippetConfigurationFormContainer.displayName = 'SnippetConfigurationFormContainer';

  SnippetConfigurationFormContainer.propTypes = {
    snippetConfig: snippetConfigShape,
    form: formShape.isRequired,
    onSubmit: PropTypes.func,
    onChangeShouldOpenSnippetOnOpeningWindow: PropTypes.func.isRequired,
    onChangeShouldLimitPopupActivationForPeriod: PropTypes.func.isRequired,
    onChangeShouldAskFeedbackIfUserAlreadyVoted: PropTypes.func.isRequired,
  };

  SnippetConfigurationFormContainer.defaultProps = {
    snippetConfig: null,
    onSubmit: noop,
  };

  return enhancer(SnippetConfigurationFormContainer);
};
