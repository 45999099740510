import React from 'react';
import {
  compose,
  lifecycle,
  branch,
  renderNothing,
} from 'recompose';
import { Helmet } from 'react-helmet';

import withRouteParams from 'react/hoc/withRouteParams';
import connect from 'react/hoc/connectProxy';
import SnippetConfigurationPage from 'react/pages/preferences/snippet/SnippetConfigurationPage';
import ApplicationLayout from 'react/business/layout/ApplicationLayout';

import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';

import { getProduct } from 'redux/products/actions';
import { selectDetailedProductsMap } from 'redux/products/selectors';
import { productShape } from 'shapes/product';

import messages from './snippet-configuration.messages';

const mapRouteParamsToProps = ({ productId }) => ({
  productId,
});

const enhancer = compose(
  withRouteParams(mapRouteParamsToProps),
  connect(
    (state, props) => ({
      product: selectDetailedProductsMap(state)[props.productId],
    }),
    {
      getProduct,
    },
  ),

  // Load product on mount, and display nothing until loaded.
  compose(
    lifecycle({
      /**
       * Load product on mount.
       */
      async componentDidMount() {
        await this.props.getProduct(this.props.productId);
      },
    }),

    // render nothing if product is not loaded
    branch(
      props => !props.product,
      renderNothing,
    ),
  ),
);

const SnippetConfiguration = ({ product }) => (
  <ApplicationLayout>
    <FormattedMessageChildren
      {...messages.TITLE}
      values={{ productName: product.name }}
    >
      {message => (
        <Helmet>
          <title>{message}</title>
        </Helmet>
      )}
    </FormattedMessageChildren>

    <SnippetConfigurationPage productId={product.id} productName={product.name} />
  </ApplicationLayout>
);

SnippetConfiguration.displayName = 'SnippetConfiguration';

SnippetConfiguration.propTypes = {
  product: productShape,
};

SnippetConfiguration.defaultProps = {
  product: null,
};

export default enhancer(SnippetConfiguration);
