import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { noop } from 'lodash';

import SnippetConfigurationInfoForm from './SnippetConfigurationForm.container';

export default (WrappedComponent) => {
  const enhancer = compose(
    SnippetConfigurationInfoForm,
  );

  const SnippetConfigurationFormChildrenContainer = ({
    onSubmit,
    children,
    ...props
  }) => (
    <form onSubmit={onSubmit}>
      { children({
        form: (
          <WrappedComponent {...props} />
        ),
      }) }
    </form>
  );

  SnippetConfigurationFormChildrenContainer.displayName = 'SnippetConfigurationFormChildrenContainer';

  SnippetConfigurationFormChildrenContainer.propTypes = {
    onSubmit: PropTypes.func,
    children: PropTypes.func,
  };

  SnippetConfigurationFormChildrenContainer.defaultProps = {
    onSubmit: noop,
    children: noop,
  };

  return enhancer(SnippetConfigurationFormChildrenContainer);
};
