import { defineMessages } from 'react-intl';

export default defineMessages({
  SNIPPET_CONFIGURATION_FORM_DIALOG_TITLE: {
    id: 'snippet.configuration.info.dialog.title',
    defaultMessage: 'Snippet configuration',
  },
  SNIPPET_CONFIGURATION_FORM_FAILURE_TITLE: {
    id: 'snippet.configuration.info.failure.title',
    defaultMessage: 'An error occurred',
  },
  SNIPPET_CONFIGURATION_FORM_FAILURE_MESSAGE: {
    id: 'snippet.configuration.failure.message',
    defaultMessage: 'Your snippet configuration could not be updated.',
  },
  SNIPPET_CONFIGURATION_FORM_SUCCESS_TITLE: {
    id: 'snippet.configuration.info.success.title',
    defaultMessage: 'Well done',
  },
  SNIPPET_CONFIGURATION_FORM_SUCCESS_MESSAGE: {
    id: 'snippet.configuration.success.message',
    defaultMessage: 'Your snippet configuration was updated successfully.',
  },

  SNIPPET_CONFIGURATION_FORM_TITLE_BUTTON: {
    id: 'snippet.configuration.form.title.button',
    defaultMessage: 'Button configuration',
  },

  SNIPPET_CONFIGURATION_FORM_FIELD_SHOW_BUTTON: {
    id: 'snippet.configuration.form.field.shouldShowButtonAddFeedback',
    defaultMessage: 'Show the button on your application',
  },
  SNIPPET_CONFIGURATION_FORM_FIELD_SHOW_BUTTON_TOOLTIP: {
    id: 'snippet.configuration.form.field.shouldShowButtonAddFeedback.tooltip',
    defaultMessage: 'The button "Add feedback on Tangram" will be displayed to the user of your product.',
  },

  SNIPPET_CONFIGURATION_FORM_TITLE_POPUP: {
    id: 'snippet.configuration.form.title.popup',
    defaultMessage: 'Feedback window configuration',
  },

  SNIPPET_CONFIGURATION_FORM_FIELD_DISPLAY_WINDOW: {
    id: 'snippet.configuration.form.field.shouldOpenSnippetOnOpening',
    defaultMessage: 'Display feedback pop-up on window opening',
  },
  SNIPPET_CONFIGURATION_FORM_FIELD_DISPLAY_WINDOW_TOOLTIP: {
    id: 'snippet.configuration.form.field.shouldOpenSnippetOn.windowOpening.tooltip',
    defaultMessage: 'The "Tangram product review" snippet will open the tangram feedback page when the page of your digital product will be opened.',
  },
  SNIPPET_CONFIGURATION_FORM_FIELD_NUMBER_OF_CONNECTIONS: {
    id: 'snippet.configuration.form.field.shouldOpenSnippetOnOpening.nbConnections',
    defaultMessage: 'Open the pop-up after X connections: ',
  },

  SNIPPET_CONFIGURATION_FORM_FIELD_LIMIT_ON_PERIOD: {
    id: 'snippet.configuration.form.field.limitOnPeriod',
    defaultMessage: 'Limit the pop-up activation for a determined period',
  },
  SNIPPET_CONFIGURATION_FORM_FIELD_SELECT_START_DATE: {
    id: 'snippet.configuration.form.field.startDate',
    defaultMessage: 'Select start date',
  },
  SNIPPET_CONFIGURATION_FORM_FIELD_END_DATE: {
    id: 'snippet.configuration.form.field.endDate',
    defaultMessage: 'end date',
  },
  SNIPPET_CONFIGURATION_FORM_FIELD_DATES_TOOLTIP: {
    id: 'snippet.configuration.form.field.startDate.tooltip',
    defaultMessage: 'The dates when the snippet will be enabled.',
  },

  SNIPPET_CONFIGURATION_FORM_FIELD_FREQUENCY: {
    id: 'snippet.configuration.form.field.frequency',
    defaultMessage: 'Ask the user for another feedback if he has already voted',
  },
  SNIPPET_CONFIGURATION_FORM_FIELD_FREQUENCY_TOOLTIP: {
    id: 'snippet.configuration.form.field.frequency.tooltip',
    defaultMessage: 'Select how often the pop-up will appear for a person who has already voted',
  },
  SNIPPET_CONFIGURATION_FORM_FIELD_FREQUENCY_REMINDER: {
    id: 'snippet.configuration.form.field.frequency.reminder',
    defaultMessage: 'Reminder:',
  },
});
