import agent from 'services/http';
import { mapSnippetConfigFromServer } from './snippetConfigs.mapper';

const e = encodeURIComponent;

/**
 * GET /api/products/:productId/snippetConfig.
 * Get the snippet configuration of a product.
 *
 * @param {string} productId - Product id.
 * @returns {object} - Snippet Configuration.
 */
export async function getSnippetConfig(productId) {
  const { body: snippetConfig } = await agent
    .get(`/api/products/${e(productId)}/snippetConfig`);

  return mapSnippetConfigFromServer(snippetConfig);
}

/**
 * POST /api/products/:productId/snippetConfig.
 * Create or Update the snippet configuration of a product.
 *
 * @param {string} productId - Product id.
 * @param {object} snippetConfig - Snippet configuration.
 * @returns {object} - The new Snippet Configuration.
 */
export async function upsertSnippetConfig(productId, snippetConfig) {
  const { body: newSnippetConfig } = await agent
    .post(`/api/products/${e(productId)}/snippetConfig`)
    .send(snippetConfig);

  return mapSnippetConfigFromServer(newSnippetConfig);
}
