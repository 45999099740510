import React from 'react';
import { compose, lifecycle } from 'recompose';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';

import connect from 'react/hoc/connectProxy';
import MySpaceHeader from 'react/business/mySpace/MySpaceHeader';

import SnippetConfigurationForm from 'react/business/snippet/SnippetConfigurationForm';

import { selectSnippetConfigMapByProductId, selectSnippetConfigIsLoading } from 'redux/snippet/selectors';
import { getSnippetConfig } from 'redux/snippet/actions';

import { snippetConfigShape } from 'shapes/snippetConfig';

import classNames from './snippet.configuration.module.scss';
import messages from './snippet.configuration.messages';
import SnippetButton from './SnippetPreview';

const enhancer = compose(
  connect(
    (state, props) => ({
      snippetConfig: selectSnippetConfigMapByProductId(state, props.productId),
      isLoading: selectSnippetConfigIsLoading(state, props.productId),
    }),
    {
      getSnippetConfig,
    },
  ),

  lifecycle({
    /**
     * Load data on mount.
     */
    async componentDidMount() {
      await this.props.getSnippetConfig(this.props.productId);
    },
  }),
);

const SnippetConfigurationPage = ({
  productId, productName, snippetConfig, isLoading,
}) => {
  const customTitleHeader = (
    <FormattedMessage
      {...messages.TITLE_HEADER}
      values={{ productName }}
    />
  );

  return (
    <>
      <MySpaceHeader customTitleHeader={customTitleHeader} />

      <div className={classNames.pageContainer}>
        <div className={classNames.infoTextContainer}>
          <FormattedHTMLMessage
            {...messages.SNIPPET_INFO_TEXT}
          />
        </div>
        {/* Fallback in case the library couldn't be downloaded to avoid app crash. */}
        {window.Tangram && (
          <div className={classNames.exampleZone}>
            <div className={classNames.buttonsContainers}>
              <div className={classNames.left}>
                <div className={classNames.buttonContainer}>
                  <SnippetButton productId={productId} />
                </div>
                <em><FormattedMessage {...messages.SNIPPET_INFO_LIGHT_BG} /></em>
              </div>
              <div className={classNames.right}>
                <div className={classNames.buttonContainer}>
                  <SnippetButton productId={productId} />
                </div>
                <em><FormattedMessage {...messages.SNIPPET_INFO_DARK_BG} /></em>
              </div>
            </div>
            <p className={classNames.clickMe}>
              <strong>
                &gt; <FormattedMessage {...messages.SNIPPET_INFO_CLICK_ME} /> &lt;
              </strong>
            </p>
          </div>
        )}
      </div>
      <SnippetConfigurationForm
        productId={productId}
        snippetConfig={snippetConfig}
        isLoading={isLoading}
      />
    </>
  );
};

SnippetConfigurationPage.displayName = 'SnippetConfigurationPage';

SnippetConfigurationPage.propTypes = {
  snippetConfig: snippetConfigShape,
  productId: PropTypes.string.isRequired,
  productName: PropTypes.string,
  isLoading: PropTypes.bool,
};

SnippetConfigurationPage.defaultProps = {
  snippetConfig: null,
  productName: '',
  isLoading: false,
};

export default enhancer(SnippetConfigurationPage);
