import * as SnippetConfigsService from 'services/snippet';

import { ACTIONS } from './constants';

export const GET_SNIPPET_CONFIGURATION = productId => ({
  type: ACTIONS.GET_SNIPPET_CONFIGURATION,
  productId,
});

export const GET_SNIPPET_CONFIGURATION_SUCCESS = (productId, snippetConfig) => ({
  type: ACTIONS.GET_SNIPPET_CONFIGURATION_SUCCESS,
  productId,
  snippetConfig,
});

export const GET_SNIPPET_CONFIGURATION_FAILURE = (productId, error) => ({
  type: ACTIONS.GET_SNIPPET_CONFIGURATION_FAILURE,
  productId,
  error,
});

export const getSnippetConfig = productId => async (dispatch) => {
  dispatch(GET_SNIPPET_CONFIGURATION(productId));

  try {
    const snippetConfig = await SnippetConfigsService.getSnippetConfig(productId);

    return dispatch(GET_SNIPPET_CONFIGURATION_SUCCESS(productId, snippetConfig));
  } catch (err) {
    return dispatch(GET_SNIPPET_CONFIGURATION_FAILURE(productId, err));
  }
};

export const UPSERT_SNIPPET_CONFIGURATION = productId => ({
  type: ACTIONS.UPSERT_SNIPPET_CONFIGURATION,
  productId,
});

export const UPSERT_SNIPPET_CONFIGURATION_SUCCESS = (productId, snippetConfig) => ({
  type: ACTIONS.UPSERT_SNIPPET_CONFIGURATION_SUCCESS,
  productId,
  snippetConfig,
});

export const UPSERT_SNIPPET_CONFIGURATION_FAILURE = (productId, error) => ({
  type: ACTIONS.UPSERT_SNIPPET_CONFIGURATION_FAILURE,
  productId,
  error,
});

export const upsertSnippetConfig = (productId, snippetConfig) => async (dispatch) => {
  dispatch(UPSERT_SNIPPET_CONFIGURATION(productId));

  try {
    const newSnippetConfig = await SnippetConfigsService.upsertSnippetConfig(
      productId,
      snippetConfig,
    );

    return dispatch(UPSERT_SNIPPET_CONFIGURATION_SUCCESS(productId, newSnippetConfig));
  } catch (err) {
    return dispatch(UPSERT_SNIPPET_CONFIGURATION_FAILURE(productId, err));
  }
};
