import React from 'react';
import { compose } from 'recompose';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withToastManager } from 'react-toast-notifications';

import { snippetConfigShape } from 'shapes/snippetConfig';
import globalMessages from 'config/global.messages';

import Button from 'react/generic/button/Button';

import SnippetConfigurationFormPresentation from './form/SnippetConfigurationForm.presentation';
import SnippetConfigurationFormChildrenContainer from './form/SnippetConfigurationForm.children.container';
import withSubmitForm from './form/withSubmitForm';

import classNames from './snippet-configuration-form.module.scss';

const SnippetConfigurationFormChildren = compose(
  SnippetConfigurationFormChildrenContainer,
)(SnippetConfigurationFormPresentation);

const enhancer = compose(
  withToastManager,
  withSubmitForm,
);

const SnippetConfigurationForm = ({ snippetConfig, onSubmit, isLoading }) => (
  <SnippetConfigurationFormChildren
    snippetConfig={snippetConfig}
    onSubmit={onSubmit}
    isLoading={isLoading}
  >
    {({ form }) => (
      <>
        {form}
        <div className={classNames.button}>
          <Button type="submit" disabled={isLoading}>
            <FormattedMessage {...globalMessages.SAVE} />
          </Button>
        </div>
      </>
    )}
  </SnippetConfigurationFormChildren>
);

SnippetConfigurationForm.displayName = 'SnippetConfigurationForm';

SnippetConfigurationForm.propTypes = {
  snippetConfig: snippetConfigShape,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};

SnippetConfigurationForm.defaultProps = {
  snippetConfig: null,
  onSubmit: noop,
  isLoading: false,
};

export default enhancer(SnippetConfigurationForm);
