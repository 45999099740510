import React from 'react';
import { noop } from 'lodash';
import { compose, defaultProps } from 'recompose';
import { FormattedMessage } from 'react-intl';
import connect from 'react/hoc/connectProxy';

import { ACTIONS } from 'redux/snippet/constants';
import { upsertSnippetConfig } from 'redux/snippet/actions';

import settingsMessages from 'react/pages/settings/settings.messages';

export default compose(
  defaultProps({
    onSubmitSuccess: noop,
    onSubmitFailure: noop,
  }),
  connect(
    null,
    (dispatch, props) => ({
      onSubmit: async (data) => {
        const action = await dispatch(upsertSnippetConfig(props.productId, data));

        if (
          action.type === ACTIONS.UPSERT_SNIPPET_CONFIGURATION_SUCCESS
        ) {
          props.toastManager.add(
            <FormattedMessage {...settingsMessages.TOAST_UPDATE_SUCCESS} />,
            {
              appearance: 'success',
              autoDismiss: true,
            },
          );
          return props.onSubmitSuccess();
        }
        props.toastManager.add(
          <FormattedMessage {...settingsMessages.TOAST_UPDATE_FAILURE} />,
          {
            appearance: 'error',
            autoDismiss: true,
          },
        );
        return props.onSubmitFailure(action.error);
      },
    }),
  ),
);
