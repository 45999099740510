import { defineMessages } from 'react-intl';

export default defineMessages({
  TITLE_HEADER: { id: 'snippet.configuration.title', defaultMessage: 'Tangram configuration page for the feedback snippet - {productName}' },
  SNIPPET_INFO_CLICK_ME: { id: 'snippet.configuration.clickMe', defaultMessage: 'Click on the button preview to see the popup content.' },
  SNIPPET_INFO_LIGHT_BG: { id: 'snippet.configuration.lightBg', defaultMessage: 'Light Background' },
  SNIPPET_INFO_DARK_BG: { id: 'snippet.configuration.darkBg', defaultMessage: 'Dark Background' },
  SNIPPET_INFO_TEXT: {
    id: 'snippet.configuration.information', defaultMessage: `
    <p>
    The snippet is a simple way to invite your users to add a feedback on your product.
    It displays a button to either open a popup to the feedback form, or the feedback page in a new window.
    </p>

    <p>
    This popup can also open itself automatically based on the parameters you set below.
    <em>For instance, you can edit how many visits on your website you want to wait before opening the popup,
    or if you want to ask users to give another feedback after a long period of time.</em>
    </p>

    <p>
    <strong>However, for this to work, you need to setup the Tangram snippet on your product.</strong>
    To do so, please follow the instructions on the
    <a href="https://github.com/adeo/tangram--snippet" target="_blank" rel="noopener noreferrer">Github project of the snippet</a>
    (read <a href="https://jira.adeo.com/confluence/display/SOFA/Join+the+ADEO+organization+on+GitHub" target="_blank" rel="noopener noreferrer">this</a> if you have a 404 error).
    You can set yourself up in less than five minutes, or customize the look and feel of your snippet so it best fits your needs.
    </p>
  `,
  },
});
