import { defineMessages } from 'react-intl';

export default defineMessages({
  NEVER: {
    id: 'snippet.configuration.form.field.frequency.values.never',
    defaultMessage: 'Never',
  },
  MONTH: {
    id: 'snippet.configuration.form.field.frequency.values.month',
    defaultMessage: 'Each month',
  },
  QUARTER: {
    id: 'snippet.configuration.form.field.frequency.values.quarter',
    defaultMessage: 'Each quarter',
  },
  YEAR: {
    id: 'snippet.configuration.form.field.frequency.values.year',
    defaultMessage: 'Each year',
  },
});
