import PropTypes from 'prop-types';

export const snippetConfigShape = PropTypes.shape({
  id: PropTypes.string,
  shouldShowButtonAddFeedback: PropTypes.bool,
  shouldOpenSnippetOnOpeningWindow: PropTypes.bool,
  nbConnectionsBeforeOpening: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  displayingFrequencyIfUserAlreadyVoted: PropTypes.number,
});
