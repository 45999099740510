import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  DatePicker,
  Tooltip,
  Switch,
  Radio,
  InputNumber,
} from 'antd';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import classNames from './snippet-configuration-form-presentation.module.scss';
import valuesMessages from './snippet-form-values.messages';
import { DISPLAY_FREQUENCY } from '../snippet-configuration-form.constants';
import messages from '../snippet-configuration-form.messages';

const {
  Group: RadioGroup,
  Button: RadioButton,
} = Radio;

const enhancer = compose(
  memo,
);

const SnippetConfigurationFormPresentation = ({
  shouldShowButtonAddFeedbackDecorator,
  shouldOpenSnippetOnOpeningWindowDecorator,
  visitsBeforeOpeningWindowDecorator,
  shouldLimitPopupActivationForPeriodDecorator,
  startDateDecorator,
  endDateDecorator,
  shouldAskFeedbackIfUserAlreadyVotedDecorator,
  displayingFrequencyIfUserAlreadyVotedDecorator,
  shouldOpenSnippetOnOpeningWindow,
  shouldLimitPopupActivationForPeriod,
  shouldAskFeedbackIfUserAlreadyVoted,
  isLoading,
}) => (
  <div className={classNames.sectionsContainer}>
    <div className={classNames.section}>
      <div className={classNames.title}>
        <FormattedMessage {...messages.SNIPPET_CONFIGURATION_FORM_TITLE_BUTTON} />
      </div>
      <div className={classNames.row}>
        <div className={classNames.block}>
          <div className={classNames.formLabel}>
            <FormattedMessage {...messages.SNIPPET_CONFIGURATION_FORM_FIELD_SHOW_BUTTON} />
            <Tooltip
              placement="top"
              title={(
                <FormattedMessage
                  {...messages.SNIPPET_CONFIGURATION_FORM_FIELD_SHOW_BUTTON_TOOLTIP}
                />
              )}
              className={classNames.tooltip}
            >
              <span className={classNames.infoTooltip}>
                <Asset name={ICON_MAP.info} />
              </span>
            </Tooltip>
          </div>
        </div>
        <div className={classNames.block}>
          {
            shouldShowButtonAddFeedbackDecorator(<Switch disabled={isLoading} />)
          }
        </div>
      </div>
    </div>
    <div className={classNames.section}>
      <div className={classNames.title}>
        <FormattedMessage {...messages.SNIPPET_CONFIGURATION_FORM_TITLE_POPUP} />
      </div>
      <div className={classNames.row}>
        <div className={classNames.subRowContainer}>
          <div className={classNames.subRow}>
            <div className={classNames.block}>
              <div className={classNames.formLabel}>
                <FormattedMessage
                  {...messages.SNIPPET_CONFIGURATION_FORM_FIELD_DISPLAY_WINDOW}
                />
                <Tooltip
                  placement="top"
                  title={(
                    <FormattedMessage
                      {...messages.SNIPPET_CONFIGURATION_FORM_FIELD_DISPLAY_WINDOW_TOOLTIP}
                    />
                  )}
                  className={classNames.tooltip}
                >
                  <span className={classNames.infoTooltip}>
                    <Asset name={ICON_MAP.info} />
                  </span>
                </Tooltip>
              </div>
            </div>
            <div className={classNames.block}>
              {
                shouldOpenSnippetOnOpeningWindowDecorator(
                  <Switch
                    disabled={isLoading}
                  />,
                )
              }
            </div>
          </div>
          <div
            className={classNames.subRow}
          >
            <div className={classNames.subField}>
              <FormattedMessage
                {...messages.SNIPPET_CONFIGURATION_FORM_FIELD_NUMBER_OF_CONNECTIONS}
              />
              {
                visitsBeforeOpeningWindowDecorator(
                  <InputNumber
                    disabled={isLoading || !shouldOpenSnippetOnOpeningWindow}
                    min={0}
                    className={classNames.inlineField}
                  />,
                )
              }
            </div>
          </div>
        </div>
      </div>
      <div className={classNames.row}>
        <div className={classNames.subRowContainer}>
          <div className={classNames.subRow}>
            <div className={classNames.block}>
              <div className={classNames.formLabel}>
                <FormattedMessage {...messages.SNIPPET_CONFIGURATION_FORM_FIELD_LIMIT_ON_PERIOD} />
                <Tooltip
                  placement="top"
                  title={(
                    <FormattedMessage
                      {...messages.SNIPPET_CONFIGURATION_FORM_FIELD_DATES_TOOLTIP}
                    />
                  )}
                  className={classNames.tooltip}
                >
                  <span className={classNames.infoTooltip}>
                    <Asset name={ICON_MAP.info} />
                  </span>
                </Tooltip>
              </div>
            </div>
            <div className={classNames.block}>
              {
                shouldLimitPopupActivationForPeriodDecorator(<Switch disabled={isLoading} />)
              }
            </div>
          </div>

          <div className={classNames.subRow}>
            <div className={classNames.subField}>
              <FormattedMessage
                {...messages.SNIPPET_CONFIGURATION_FORM_FIELD_SELECT_START_DATE}
              />
              {
                startDateDecorator(
                  <DatePicker
                    disabled={isLoading || !shouldLimitPopupActivationForPeriod}
                    className={classNames.inlineField}
                  />,
                )
              }
              <FormattedMessage {...messages.SNIPPET_CONFIGURATION_FORM_FIELD_END_DATE} />
              {
                endDateDecorator(
                  <DatePicker
                    disabled={isLoading || !shouldLimitPopupActivationForPeriod}
                    className={classNames.inlineField}
                  />,
                )
              }
            </div>
          </div>
        </div>
      </div>
      <div className={classNames.row}>
        <div className={classNames.subRowContainer}>
          <div className={classNames.subRow}>
            <div className={classNames.block}>
              <div className={classNames.formLabel}>
                <FormattedMessage {...messages.SNIPPET_CONFIGURATION_FORM_FIELD_FREQUENCY} />
                <Tooltip
                  placement="top"
                  title={(
                    <FormattedMessage
                      {...messages.SNIPPET_CONFIGURATION_FORM_FIELD_FREQUENCY_TOOLTIP}
                    />
                  )}
                  className={classNames.tooltip}
                >
                  <span className={classNames.infoTooltip}>
                    <Asset name={ICON_MAP.info} />
                  </span>
                </Tooltip>
              </div>
            </div>
            <div className={classNames.block}>
              {
                shouldAskFeedbackIfUserAlreadyVotedDecorator(
                  <Switch disabled={isLoading} />,
                )
              }
            </div>
          </div>

          <div className={classNames.subRow}>
            <div className={classNames.subField}>
              <FormattedMessage
                {...messages.SNIPPET_CONFIGURATION_FORM_FIELD_FREQUENCY_REMINDER}
              />
              {
                displayingFrequencyIfUserAlreadyVotedDecorator((
                  <RadioGroup
                    buttonStyle="solid"
                    disabled={isLoading || !shouldAskFeedbackIfUserAlreadyVoted}
                    className={classNames.inlineField}
                  >
                    <RadioButton value={DISPLAY_FREQUENCY.MONTH}>
                      <FormattedMessage
                        {...valuesMessages.MONTH}
                      />
                    </RadioButton>
                    <RadioButton value={DISPLAY_FREQUENCY.QUARTER}>
                      <FormattedMessage
                        {...valuesMessages.QUARTER}
                      />
                    </RadioButton>
                    <RadioButton value={DISPLAY_FREQUENCY.YEAR}>
                      <FormattedMessage
                        {...valuesMessages.YEAR}
                      />
                    </RadioButton>
                  </RadioGroup>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

SnippetConfigurationFormPresentation.displayName = 'SnippetConfigurationFormPresentation';

SnippetConfigurationFormPresentation.propTypes = {
  shouldShowButtonAddFeedbackDecorator: PropTypes.func.isRequired,
  shouldOpenSnippetOnOpeningWindowDecorator: PropTypes.func.isRequired,
  visitsBeforeOpeningWindowDecorator: PropTypes.func.isRequired,
  shouldLimitPopupActivationForPeriodDecorator: PropTypes.func.isRequired,
  shouldAskFeedbackIfUserAlreadyVotedDecorator: PropTypes.func.isRequired,
  displayingFrequencyIfUserAlreadyVotedDecorator: PropTypes.func.isRequired,
  startDateDecorator: PropTypes.func.isRequired,
  endDateDecorator: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  shouldOpenSnippetOnOpeningWindow: PropTypes.bool,
  shouldLimitPopupActivationForPeriod: PropTypes.bool,
  shouldAskFeedbackIfUserAlreadyVoted: PropTypes.bool,
};

SnippetConfigurationFormPresentation.defaultProps = {
  isLoading: false,
  shouldOpenSnippetOnOpeningWindow: false,
  shouldLimitPopupActivationForPeriod: false,
  shouldAskFeedbackIfUserAlreadyVoted: false,
};

export default enhancer(SnippetConfigurationFormPresentation);
