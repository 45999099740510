import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

export default class SnippetPreview extends React.PureComponent {
  static propTypes = {
    productId: PropTypes.string.isRequired,
  };

  /**
   * Constructor.
   *
   * @param {object} props - Props.
   */
  constructor(props) {
    super(props);

    this.MOUNT_ELEMENT_ID = `tangram-snippet-demo-mount-root-${uniqueId()}`;
  }

  /**
   * Create snippet after the DOM element is ready to be mounted on.
   */
  componentDidMount() {
    // Fallback in case the library couldn't be downloaded to avoid app crash.
    if (window.Tangram) {
      this.snippet = new window.Tangram({
        // Not passing userLdap on purpose, we don't want the popup to open
        // during demo.
        host: window.location.origin,
        productId: this.props.productId,
        buttonOptions: {
          mountElement: document.getElementById(this.MOUNT_ELEMENT_ID),
          // We want the button to always be available so we manually control it
          // to avoid having two buttons.
          noShowOnStartup: true,
          // Reset position absolute to display the button in the right container.
          style: {
            position: 'initial',
          },
        },
      });

      // And now force open button.
      this.snippet.showButton();
    }
  }

  /**
   * Properly unmount the button even if the DOM node is gonna be deleted.
   */
  componentWillUnmount() {
    if (this.snippet) {
      this.snippet.hideButton();
    }
  }

  /**
   * Render component.
   *
   * @returns {object} - JSX to render.
   */
  render() {
    return (
      <div id={this.MOUNT_ELEMENT_ID} />
    );
  }
}
